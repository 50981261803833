@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/timeline/main.css';
@import '~@fullcalendar/resource-timeline/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/daygrid/main.css';


.main{
	.container{

		@media (min-width: 1200px){
			max-width: none!important;
		}		
	}
}

.header-address{
	font-size: 70%;
	color: #c9dcff;
	line-height: 1.1em;
}

.app-header{
	.nav-item{
		margin: 5px!important;
	}

}

	.logo{
		width: 80px;
		@media screen and (max-width: 1000px){
			width: 45px;
		}
	}

.no-small-screen{
	@media screen and (max-width: 1000px){
		display: none;
	}
}

.inline-tip{
	text-align: center;
	color:black;
}


.react-datepicker-popper{
	z-index:12!important;
}

.nobr{
	white-space: nowrap;
}

.card-header svg{

}

.alert-notice{
	padding: 5px!important;
	background-color: #fff7dc!important;
	border-radius: 8px!important;
	border: 1px solid #ffcf3d!important;
	margin-bottom: 8px!important;
	font-size: 12px!important;
}

.area-stats-badge{
	margin-left: 12px;
	white-space: nowrap;
}

.app-body{
	padding-top: 30px;
}

.fc-resource-area{
	width: 90px!important;
}

#bookable-chooser{
	width: auto;
}

.section-icon{
	font-size: 22px;
	margin-right: 7px;
}

.location-badge{
	color: white!important;
	background-color: #828282!important;
	font-size:13px!important;
	margin-top: 8px!important;
}

.announcement-card{
	
	background-color: #d2e9fd!important;
	.card-header{
		background-color: #d2e9fd!important;
		padding-right: 5px!important;
	}
	.announcement-button{
		margin-left: 20px;
	}
}

.calendar-container{
	.card-header{
		svg{
			
			font-size: 21px;
		}
	}
	.fc-resourceTimeGridDay-view{
		.fc-scroller{
			height: 800px!important;
		}
	}

	.fc-time-grid{
		.fc-slats td{
			color: #00008c;
		}
	} 

	.date-button{
		font-size: 18px;
		font-weight: bold;
		padding: 4px;
		padding-left: 8px;
		padding-right: 8px;
		background-color: #9ee1ff;
		color: black;
		margin-left: 3px;
		margin-right: 3px;

	}

	.calendar-nav-button{
		border-radius: 15px;
		color: black;

	}
	.right{
		border-top-left-radius:0px;
		border-bottom-left-radius:0px;
	}
	.left{
		border-top-right-radius:0px;
		border-bottom-right-radius:0px;
	}

	.fc-content{
	    cursor: pointer!important;
	}

	.fc-time-grid-event .fc-time span{
		background: #e3e3e3;
		color: black;
		padding: 2px; paddin-left: 3px;
		overflow: hidden;
		font-size: 9px;
	}

	.fc-widget-header{
		.fc-cell-text{
			color: black;
		}
	}

	.fc-time-grid-event{
		overflow: hidden;
	}

	.event-edit-button{
		color: black!important;
		background: #eeeeeead!important;

		padding: 3px;
		border-radius: 5px;
	}
	.event-username{
		background: black;
		padding: 1px;
		line-height: 117%;
		font-size: 10px;
	}
	.event-subject{
		color: black!important;
		background: #eeeeeead!important;
		padding: 6px;
		line-height: 117%;
		font-size: 10px;
	}

}

.booking-modal{
	.user-name{
		padding-top: 4px;
		font-weight: bold;
	}
	.username-clear{
		margin-left: 5px;
		margin-top: 8px;
		font-size: 19px;
		color: #909090;
	}
	.booking-cancel-warning{
		background: #ffb3b3;
		margin-right: 20px;
	}
	.event-log{
		td, th{
			font-size: 10px;
			text-align: center;
		}
		td{
			font-size: 9px;
		}
		overflow: auto;
	}
}

.trip-estimator{
	max-width: 360px;
	.badge{
		margin-bottom: 10px;
	}
	table{
		margin-bottom: 0px;
		td, th{
			font-size: 13px;
			padding: 0.15rem;
			text-align: center;
		}
		td{
			font-size: 24px;
		}

		.time-item{
			color: #453d8e;
		}
		.distance-item{
			color: #712d19;
		}

	}

	.card{

	}

	.card-body{
		background: #e5e5e5;
		padding: 0.55rem;
	}

	#member-type-field{
		font-size: 12px;
		background: #fff7d9;

	}

	.estimate-total{
		font-size: 24px;
	}
	.estimate-footnote{
		color: #86868a;
	}
}

.react-datepicker-wrapper{

	.form-control:disabled, .form-control[readonly] {
    	background-color: #fcffd4;
    	opacity: 1;
    }
}

.react-datepicker__tab-loop{
	display: inline-block;
}

.fc-bgevent {
	cursor: not-allowed;
}